<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  showExpand
  componentInfo="ReportFinanceInfo"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableFinance',
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number') },
        { value: 'payment_date', text: this.$t('paymentDate') },
        { value: 'price_form1', text: `${this.$t('price')} ${this.$t('firstForm')}` },
        { value: 'sum_to_distribution_f1.amount', text: `${this.$t('total')} ${this.$t('firstForm')}` },
        { value: 'price_form2', text: `${this.$t('price')} ${this.$t('secondForm')}` },
        { value: 'sum_to_distribution_f2.amount', text: `${this.$t('total')} ${this.$t('secondForm')}` },
        { value: 'profit', text: this.$t('profit') },
        { value: 'document_info', text: this.$t('documentInfo') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false },
        { text: '', value: 'data-table-expand' }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({ name: 'sailor', params: { id: item.sailor_id } }),
          color: 'blue',
          name: 'mdi-account-circle-outline',
          tooltip: 'tooltip.goToSailor',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listFinance,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListFinance']),
    async getData (params) {
      this.getListFinance({ params })
    }

  }
}
</script>
